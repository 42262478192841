import styles from "../scss/home.module.scss";
export default function HomePage() {
  return (
    <>
      <Title>$PAPA</Title>
      <BuyLink />
      <Title>About</Title>
      <About />
      <Title>Social Media</Title>
      <SocialMedia />
      <CopyRight />
    </>
  );
}

function Title({ children }) {
  return <div className={styles["title"]}>{children}</div>;
}

function BuyLink() {
  const handleClickPump = () => {
    window.open(
      "https://pump.fun/coin/jhGf6cAwBtMBtv6gPWLucgCMVwhcqyH5o34Vj3Tpump"
    );
  };
  return (
    <div className={styles["BuyLink"]}>
      <img
        src="./static/papa.png"
        alt="pump fun logo"
        className={styles["BuyLink__papa"]}
      />
      <div className={styles["BuyLink__font"]} onClick={handleClickPump}>
        <img
          src="./static/pumpLogo.png"
          alt="pump fun logo"
          className={styles["BuyLink__logo"]}
        />
        Buy on Pump.fun
      </div>
    </div>
  );
}

function About() {
  return (
    <div className={styles["about"]}>
      <div>Papa is Pepe's distant cousin</div>
      <img
        src="./static/pepe.png"
        alt="pump fun logo"
        className={styles["about__pepe"]}
      />
    </div>
  );
}

function SocialMedia() {
  const handleClickTikTok = () => {
    window.open("https://www.tiktok.com/@papacoin1b", "_blank");
  };
  const handleClickX = () => {
    window.open("https://x.com/syasaii1", "_blank");
  };
  const handleClickTelegram = () => {
    window.open("https://t.me/+Bjqzaf9cWG84Njk1", "_blank");
  };
  return (
    <div className={styles["SocialMedia"]}>
      <img
        src="./static/tiktok.png"
        alt="TikTok logo"
        className={styles["SocialMedia__img"]}
        onClick={handleClickTikTok}
      />
      <img
        src="./static/x.png"
        alt="X logo"
        className={styles["SocialMedia__img"]}
        onClick={handleClickX}
      />
      <img
        src="./static/telegram.png"
        alt="Telegram logo"
        className={styles["SocialMedia__img"]}
        onClick={handleClickTelegram}
      />
    </div>
  );
}

function CopyRight() {
  return (
    <div className={styles["copyRight"]}>
      © 2024 by Papa. All rights reserved!
    </div>
  );
}
